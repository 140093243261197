import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "lato-font";
import "@fontsource/montserrat";
import 'typeface-playfair-display';
import "fontsource-bebas-neue";
import "@fortawesome/fontawesome-free/css/all.css";
import VueGtm from '@gtm-support/vue2-gtm';


Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

Vue.prototype.formatDate = function (date) {
  if (!date) return null;

  //new Date().toLocaleDateString('en-us', { year:"numeric", month:"short"});
  //console.log("***DATE*** " + typeof(test_1) + " " + test_1);
  
  const [year, month, day] = date.split("-");
  return `${day}/${month}/${year}`;
};

Vue.use(VueGtm, {
  id: "GTM-NLPLBQF", // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
  queryParams: {
    // Add url query string when load gtm.js with GTM ID (optional)
   // gtm_auth: "Zcb_9REnRAuq6uOWNsWyuw",
   // gtm_preview: "env-4",
   // gtm_cookies_win: "x",
  },
  defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  ignoredViews: ["homepage"], // Don't trigger events for specified router names (case insensitive) (optional)
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
});