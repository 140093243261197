import store from '../store/index.js';

const A4_FORMAT_PARAM = 1.414;
const TRANSFORM_TEMPLATE_COMPONENTS = 0.85;
const BUILDING_PATH = '/hacercurriculum';

function calculateCvheight(element, isLongCv){
  return `${element.offsetWidth * A4_FORMAT_PARAM * ((element && isLongCv) ? 2:1)}px`;
};
function calculateA4Height(element){
  return `${(element.offsetWidth * A4_FORMAT_PARAM * TRANSFORM_TEMPLATE_COMPONENTS) + (element.offsetWidth * A4_FORMAT_PARAM * 0.075)}px`;
};
function updateHeight() {
  let currentPath = location.pathname;
  if (currentPath == BUILDING_PATH) {
    // Update isTwoPagesCv variable depending on whether Cv is 2 pages or not
    store.dispatch('updatePagesCv', store.state.isTwoPagesCv);
    let cvTemplate = document.getElementsByClassName("globalTemplate")[0];
    cvTemplate.style.height = calculateCvheight(cvTemplate, store.state.isTwoPagesCv);
    let cvFramework = document.getElementsByClassName("templateComponents");
    if(cvFramework.length>0){
      // Define hr position to delimit A4 size
      let limitA4 = document.getElementById("limitA4");
      limitA4.style.top = (store.state.isTwoPagesCv)? calculateA4Height(cvTemplate): "-100px";
      // Define margin top of template
      cvTemplate.style.marginTop = (store.state.isTwoPagesCv)? "-13%" : "-3%";
    }
  }
}; 
function getDeviceTextType(object) {
  if (object.$vuetify.breakpoint.name === "xs") {
    return "text-caption";
  } else if (object.$vuetify.breakpoint.name === "sm") {
    return "text-caption";
  } else if (object.$vuetify.breakpoint.name === "md") {
    return "text-sm-subtitle-2";
  } else if (object.$vuetify.breakpoint.name === "lg") {
    return "text-sm-subtitle-2";
  } else if (object.$vuetify.breakpoint.name === "xl") {
    return "text-sm-subtitle-1";
  } else {
    return "text-sm-subtitle-1";
  }
};
function isMobileDevice(object){
  return object.$vuetify.breakpoint.mobile;
};
function isLargeCv(){
  let heightA4 = document.getElementsByClassName('globalTemplate')[0].offsetWidth * A4_FORMAT_PARAM;
  var cvElements = document.getElementsByClassName('verifyPagesCv');
  for (const index in cvElements) {
    if ((cvElements[index].offsetHeight + cvElements[index].offsetTop) > heightA4) {
      return true;
    } 
  }
  return false;
};

function setFontSizes(object, selectedSize){
  let sizePixels=14;
  let currentPath = location.pathname;

  sizePixels = 
  (object.$vuetify.breakpoint.name === "md" && selectedSize === 'Small') ? 
    ((currentPath == BUILDING_PATH) ? 8 : 5 ):
  (object.$vuetify.breakpoint.name === "md" && selectedSize === 'Medium') ?
    ((currentPath == BUILDING_PATH) ? 10 : 7 ):
  (object.$vuetify.breakpoint.name === "md" && selectedSize === 'Large') ?
    ((currentPath == BUILDING_PATH) ? 12 : 9 ):
  (object.$vuetify.breakpoint.name === "lg" && selectedSize === 'Small') ?
    ((currentPath == BUILDING_PATH) ? 11.5 : 9 ):
  (object.$vuetify.breakpoint.name === "lg" && selectedSize === 'Medium') ?
    ((currentPath == BUILDING_PATH) ? 13.5 : 11 ):
  (object.$vuetify.breakpoint.name === "lg" && selectedSize === 'Large') ?
    ((currentPath == BUILDING_PATH) ? 15.5 : 13 ):
  (object.$vuetify.breakpoint.name === "xl" && selectedSize === 'Small') ?
    ((currentPath == BUILDING_PATH) ? 16 : 13 ):
  (object.$vuetify.breakpoint.name === "xl" && selectedSize === 'Medium') ?
    ((currentPath == BUILDING_PATH) ? 18 : 15 ):
  (object.$vuetify.breakpoint.name === "xl" && selectedSize === 'Large') ?
    ((currentPath == BUILDING_PATH) ? 20 : 17 ): 
  20;
  
  if(document.getElementsByClassName('globalTemplate').length >0){
    document.getElementsByClassName('globalTemplate')[0].style.fontSize = `${sizePixels}px`;
  }
};

export default {
  calculateCvheight,
  calculateA4Height,
  updateHeight,
  getDeviceTextType,
  isMobileDevice,
  isLargeCv,
  setFontSizes
};
