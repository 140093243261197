<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "App",

  data: () => ({
    //
  }),
  methods: {
    ...mapActions(["load"]),
  },
  created() {
    this.load();
  },
};
</script>
<style lang="scss" src="../CSS/index.scss" ></style>

