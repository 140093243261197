import Vue from "vue";
import Vuex from "vuex";
import utilities from "../services/utilities";
// import blanckUser from "../assets/blank_profile.png";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    step: 1,
    loader: false,
    isTwoPagesCv: false,
    cv: {
      contact: {
        firstName: "",
        lastName: "",
        address: "",
        city: "",
        phone: "",
        postal: "",
        email: "",
      },
      listEducation: [],
      listExperience: [],
      listSkill: [],
      bio: "",
      img: "",
      templateType: "Elegant",
      typeOfLetter: "Roboto",
      sizeOfLetter: "Medium",
      align : "Left"
    },
    
  },
  mutations: {
    setNextStep(state) {
      state.step += 1;
    },
    setTwoPagesCv(state , type){
      state.isTwoPagesCv = type;
    },
    setTemplateType(state , type){
      state.cv.templateType = type;
    },
    setLoader(state,bool){
      state.loader = bool;
    },
    setBackStep(state) {
      state.step -= 1;
    },
    setContact(state, contact) {
      state.cv.contact = contact;
      localStorage.setItem("cv", JSON.stringify(state.cv));
    },
    setExperience(state, experience) {
      state.cv.listExperience.push(experience);
      let actualE = state.cv.listExperience.filter(e => e.checkbox === true);
      let otherE = state.cv.listExperience.filter(e => e.checkbox === false);
      otherE.sort(
        function(a, b) { 
          a = new Date(a.dateEnd); 
          b = new Date(b.dateEnd); 
          return a>b ? -1 : a<b ? 1 : 0; }
      );
      state.cv.listExperience = actualE.concat(otherE);
      localStorage.setItem("cv", JSON.stringify(state.cv));
    },
    setBio(state, bio) {
      state.cv.bio = bio;
      localStorage.setItem("cv", JSON.stringify(state.cv));
    },
    setImg(state, img) {
      state.cv.img = img;
      localStorage.setItem("cv", JSON.stringify(state.cv));
    },
    setSkill(state, skill) {
      state.cv.listSkill.push(skill);
      localStorage.setItem("cv", JSON.stringify(state.cv));
    },
    setEducation(state, education) {
      state.cv.listEducation.push(education);
      state.cv.listEducation.sort(
        function(a, b) { 
          a = new Date(a.date); 
          b = new Date(b.date); 
          return a>b ? -1 : a<b ? 1 : 0; }
      );
      localStorage.setItem("cv", JSON.stringify(state.cv));
    },
    removeEducation(state, education) {
      state.cv.listEducation = state.cv.listEducation.filter(
        (i) => i !== education
      );
      localStorage.setItem("cv", JSON.stringify(state.cv));
    },
    removeExperience(state, experience) {
      state.cv.listExperience = state.cv.listExperience.filter(
        (i) => i !== experience
      );
      localStorage.setItem("cv", JSON.stringify(state.cv));
    },
    removeSkill(state, skill) {
      state.cv.listSkill = state.cv.listSkill.filter((i) => i !== skill);
      localStorage.setItem("cv", JSON.stringify(state.cv));
    },
    updateCv(state, cv) {
      state.cv = cv;
    },
    initial(state) {
      localStorage.setItem("cv", JSON.stringify(state.cv));
    },
  },
  actions: {
    updatePagesCv({ commit }, cvIsLargeStore){
      let currentIsTwoPages = utilities.isLargeCv();
      (cvIsLargeStore != currentIsTwoPages) ? commit("setTwoPagesCv",currentIsTwoPages) : null;
    },
    setNextStep({ commit }) {
      commit("setNextStep");
    },
    setTemplateType({ commit }, type) {
      commit("setTemplateType", type);
    },
    setLoader({ commit },bool) {
      commit("setLoader", bool);
    },
    setBackStep({ commit }) {
      commit("setBackStep");
    },
    setContact({ commit }, contact) {
      commit("setContact", contact);
    },
    setBio({ commit }, bio) {
      commit("setBio", bio);
    },
    setEducation({ commit }, education) {
      commit("setEducation", education);
    },
    setImg({ commit }, img) {
      commit("setImg", img);
    },
    setExperience({ commit }, experience) {
      commit("setExperience", experience);
    },
    setSkill({ commit }, skill) {
      commit("setSkill", skill);
    },
    removeEducation({ commit }, education) {
      commit("removeEducation", education);
    },
    removeExperience({ commit }, experience) {
      commit("removeExperience", experience);
    },
    removeSkill({ commit }, skill) {
      commit("removeSkill", skill);
    },
    updateCv({commit}, cv){
      commit("updateCv",cv);
    },
    load({ commit }) {
      if (localStorage.getItem("cv") !== null) {
        commit("updateCv", JSON.parse(localStorage.getItem("cv")));
        return;
      }
      commit("initial");
    },
    updateCvInStorage({commit}){
      commit("initial");
    },
  },
  modules: {},
});
